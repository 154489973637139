import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import ChatUI from '@quickenloans-ui-suite/chat';
import ChatLink from '../../Atoms/ChatLink';

const ChatWrapper = () => {
    const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'local';
    const overrides = {
        domainOverride: {
            ...(activeEnv === 'qa' && {'www.quickenloans.com': 'qa.quickenloans.com'})
        }
    };
    const [portal, setPortal] = useState(<></>);
    useEffect(() =>
    {
        setPortal(ReactDOM.createPortal(<ChatUI link={ChatLink} overrides={overrides} />, document.body));
    }, []);

    return <>{portal}</>;
}

export default ChatWrapper;
